import Chainable from "./Chainable.mts";
import Locale from "./Locale.mts";

namespace Money {
  export type Currency = "USD" | "CAD";
  export let currency: Currency = "USD";
  export const format = Chainable.create(
    Chainable.wrapFactory(
      ({
        alwaysShowCents = false,
        noSign = false,
        withPlus = false,
        defaultStr = "$-",
      }) =>
        (cents: number | undefined | null) => {
          if (cents == null || Number.isNaN(cents)) return defaultStr;

          const str = new Intl.NumberFormat(Locale.current, {
            style: "currency",
            currency,
            currencyDisplay: "narrowSymbol",
            ...(cents % 100 === 0 &&
              !alwaysShowCents && {
                minimumFractionDigits: 0,
              }),
          }).format((noSign ? Math.abs(cents) : cents) / 100);

          return withPlus && cents > 0 ? `+${str}` : str;
        },
    ),
    {
      cents: { alwaysShowCents: true },
      noSign: { noSign: true },
      withPlus: { withPlus: true },
      default: (str: string) => ({ defaultStr: str }),
    },
  );
}

export default Money;
