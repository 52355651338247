import { batch } from "solid-js";
import { reconcile } from "solid-js/store";

import CreditLine, {
  CreditLineCanada,
} from "@repo/models-kikoff/lending/CreditLine";
import Transaction, {
  TransactionCanada,
} from "@repo/models-kikoff/Transaction";
import canadaAdminRpc from "@repo/protobuf/api/canadaAdminRpc";
import { CreditLineDetails as CanadaCreditLineDetailsPb } from "@repo/protobuf/gen/kikoff_canada/protobuf/models/asgard/credit_line_details_pb";
import Protobuf from "@repo/protobuf/utils";
import FeatureStore from "@repo/utils-solid/FeatureStore";

export type ExtendedCreditLine = CreditLine & {
  furnishedAt?: Date;
  furnishConfirmedAt?: Date;
};

export namespace ExtendedCreditLineCanada {
  export const normalize = (proto: CanadaCreditLineDetailsPb) => ({
    ...CreditLineCanada.normalize(proto.creditLine!),
    furnishedAt: Protobuf.Timestamp.toDate(proto.furnishedAt),
    furnishConfirmedAt: Protobuf.Timestamp.toDate(proto.furnishConfirmedAt),
  });
}

const initialState = {
  byId: {} as Record<CreditLine.Id, ExtendedCreditLine>,
  transactionsFor: {} as Record<CreditLine.Id, Transaction[]>,
  statementsFor: {} as Record<CreditLine.Id, CreditLine.Statement[]>,
};

export const { Provider, useCreditLines } = FeatureStore.init(
  "CreditLines",
  initialState,
  ([store, setStore], { attachAction, withLogger }) => {
    const accessors = {
      byId: (id: CreditLine.Id) => store.byId[id],
      transactionsFor: attachAction(
        (id: CreditLine.Id) => store.transactionsFor[id],
        (id) => actions.fetchTransactionsFor(id)
      ),
      statementsFor: attachAction(
        (id: CreditLine.Id) => store.statementsFor[id],
        (id) => actions.fetchStatementsFor(id)
      ),
    };

    const mutations = {
      one(creditLine: ExtendedCreditLine) {
        setStore("byId", creditLine.id, reconcile(creditLine));
      },
      many(creditLines: ExtendedCreditLine[]) {
        batch(() => {
          for (const creditLine of creditLines) {
            mutations.one(creditLine);
          }
        });
      },
    };

    const usa = {
      actions: {} as never,
    };

    const canada = {
      actions: {
        fetchTransactionsFor(creditLineId: CreditLine.Id) {
          return canadaAdminRpc.CreditLines.listTransactions({
            creditLineId,
          }).then(({ transactions }) => {
            setStore(
              "transactionsFor",
              creditLineId,
              reconcile(transactions.map(TransactionCanada.normalize), {
                key: "id",
              })
            );
          });
        },
        fetchStatementsFor(creditLineId: CreditLine.Id) {
          return canadaAdminRpc.CreditLines.listStatements({
            creditLineId,
          }).then(({ statements }) => {
            setStore(
              "statementsFor",
              creditLineId,
              reconcile(statements.map(CreditLineCanada.Statement.normalize), {
                key: "id",
              })
            );
          });
        },
        close(creditLineId: CreditLine.Id) {
          return canadaAdminRpc.CreditLines.close({ creditLineId }).then(
            ({ creditLine }) => {
              setStore(
                "byId",
                creditLineId,
                CreditLineCanada.normalize(creditLine!)
              );
            }
          );
        },
      },
    };

    const actions = withLogger("action", {
      many: mutations.many,
      ...(import.meta.env.API_ADAPTER === "canada"
        ? canada.actions
        : usa.actions),
    });

    return { accessors, actions };
  }
);
