import {
  Message,
  PlainMessage,
  Timestamp as ProtobufTimestamp,
} from "@bufbuild/protobuf";

import { msIn } from "@repo/utils/Duration";

export type MessageFrom<T extends Message<T>> = PlainMessage<T>;

export { type PartialMessage } from "@bufbuild/protobuf";

namespace Protobuf {
  type Timestamp = MessageFrom<ProtobufTimestamp>;

  export namespace Timestamp {
    export const toNumber = (timestamp: Timestamp) =>
      Number(timestamp.seconds) * msIn.second;
    export const toDate = <T extends Timestamp | undefined>(
      timestamp: T
    ): Date | Extract<T, undefined> =>
      timestamp && new Date(toNumber(timestamp));
    export const fromDate = (date: Date) => ProtobufTimestamp.fromDate(date);
  }
}

export default Protobuf;
