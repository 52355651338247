import t from "@repo/localization/string.macro";
import { UpdateAddressRequest } from "@repo/protobuf/gen/web/public/onboarding_pb";
import { MessageFrom, PartialMessage } from "@repo/protobuf/utils";
import Address from "@repo/utils/Address";
import GoogleMaps from "@repo/utils-client/external/GoogleMaps";

export namespace AddressUsa {
  export const normalize = (
    proto: MessageFrom<UpdateAddressRequest>
  ): Address => ({
    addressLine1: proto.addressLine1,
    addressLine2: proto.addressLine2,
    locality: proto.city,
    subdivision: proto.state,
    postalCode: proto.zip,
    country: "US",
  });
  export const denormalize = (
    address: Address
  ): PartialMessage<UpdateAddressRequest> => ({
    addressLine1: address.addressLine1,
    addressLine2: address.addressLine2,
    city: address.locality,
    state: address.subdivision,
    zip: address.postalCode,
  });

  export const missingErrorByComponent = {
    addressLine1: () => t`Address requires street.`,
    addressLine2: () => t`Address requires Apt/Ste.`,
    locality: () => t`Address requires city.`,
    subdivision: () => t`Address requires state.`,
    postalCode: () => t`Address requires zip code.`,
    country: () => t`Address requires country.`,
  };

  export const stateByAbbreviation = {
    AK: "Alaska",
    AL: "Alabama",
    AR: "Arkansas",
    AS: "American Samoa",
    AZ: "Arizona",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DC: "District of Columbia",
    DE: "Delaware",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    IA: "Iowa",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    MA: "Massachusetts",
    MD: "Maryland",
    ME: "Maine",
    MI: "Michigan",
    MN: "Minnesota",
    MO: "Missouri",
    MS: "Mississippi",
    MT: "Montana",
    NC: "North Carolina",
    ND: "North Dakota",
    NE: "Nebraska",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NV: "Nevada",
    NY: "New York",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VA: "Virginia",
    VI: "Virgin Islands",
    VT: "Vermont",
    WA: "Washington",
    WI: "Wisconsin",
    WV: "West Virginia",
    WY: "Wyoming",
  };
}

export namespace AddressCanada {
  export const missingErrorByComponent = {
    addressLine1: () => t`Address requires street.`,
    addressLine2: () => t`Address requires Apt/Ste.`,
    locality: () => t`Address requires city.`,
    subdivision: () => t`Address requires province.`,
    postalCode: () => t`Address requires postal code.`,
    country: () => t`Address requires country.`,
  };

  export const provinceByAbbreviation = {
    AB: "Alberta",
    BC: "British Columbia",
    MB: "Manitoba",
    NB: "New Brunswick",
    NL: "Newfoundland and Labrador",
    NS: "Nova Scotia",
    NT: "Northwest Territories",
    NU: "Nunavut",
    ON: "Ontario",
    PE: "Prince Edward Island",
    QC: "Quebec",
    SK: "Saskatchewan",
    YT: "Yukon",
  };
}

export namespace AddressDefault {
  export function formatError(error: unknown) {
    if (error instanceof GoogleMaps.Place.InvalidPlaceError) {
      const res = new GoogleMaps.Place.InvalidPlaceError();
      res.message = t`Invalid Google Maps location.`;
      return res;
    }
    if (error instanceof GoogleMaps.Place.MissingComponentError)
      return new GoogleMaps.Place.MissingComponentError(
        error.component,
        (import.meta.env.API_ADAPTER === "canada"
          ? AddressCanada
          : AddressUsa
        ).missingErrorByComponent[error.component]()
      );
    return error;
  }
  formatError.wrapFn = <T>(fn: () => T) => {
    try {
      return fn();
    } catch (e) {
      throw formatError(e);
    }
  };

  export type Subdivision = (typeof Subdivision.list)[number];
  export namespace Subdivision {
    export const list =
      import.meta.env.API_ADAPTER === "canada"
        ? Object.keys(AddressCanada.provinceByAbbreviation)
        : Object.keys(AddressUsa.stateByAbbreviation);

    export const format = (subdivision: Subdivision) =>
      // Type depends on build time variable, as long as subdivision is coming
      // from `list`, compatibility is guaranteed
      import.meta.env.API_ADAPTER === "canada"
        ? // @ts-expect-error
          AddressCanada.provinceByAbbreviation[subdivision]
        : // @ts-expect-error
          AddressUsa.stateByAbbreviation[subdivision];
  }
}
