import Country from "./Country.mts";

namespace PostalCode {
  export const regex = {
    CA: /^[a-z][0-9][a-z] ?[0-9][a-z][0-9]$/i,
    US: /^[0-9]{5}(?:-[0-9]{4})?$/,
  } satisfies Record<Country, RegExp>;

  export const validate = Object.fromEntries(
    Object.entries(regex).map(([country, regex]) => [
      country,
      (postalCode: string) => regex.test(postalCode),
    ]),
  ) as Record<Country, (postalCode: string) => boolean>;
}

export default PostalCode;
