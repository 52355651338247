type Locale = (typeof Locale.list)[number];

namespace Locale {
  export const list = ["en", "fr", "es"] as const;
  export const emoji = (locale = current) => {
    const map = {
      en: "🇬🇧",
      fr: "🇫🇷",
      es: "🇪🇸",
    } satisfies Record<Locale, string>;
    return map[locale];
  };
  export const format = (() => {
    const map = {
      en: "English",
      fr: "Français",
      es: "Español",
    } satisfies Record<Locale, string>;

    return (locale = current) => map[locale];
  })();
  export const primaryCountry = (() => {
    const map = {
      en: "UK",
      fr: "FR",
      es: "ES",
    } satisfies Record<Locale, string>;

    return (locale = current) => map[locale];
  })();
  export let current: Locale = "en";
}

export default Locale;
