import { IntlMessageFormat } from "intl-messageformat";

import Locale from "@repo/utils/Locale";

export default function t(
  locales: Record<string, string>,
  { defaultMessage = "", expressions = {} as Record<string, any> } = {}
) {
  const message =
    locales?.[{ en: "en", fr: "fr_CA", es: "es" }[Locale.current]] ||
    defaultMessage;

  return new IntlMessageFormat(message, Locale.current).format(expressions);
}
