// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file web/public/external_bank_accounts.proto (package web.public, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Error } from "./error_pb.js";
import { Money } from "./money_pb.js";

/**
 * @generated from message web.public.AccountVerifier
 */
export class AccountVerifier extends Message<AccountVerifier> {
  /**
   * @generated from field: web.public.AccountVerifier.AcountVerifierType type = 1;
   */
  type = AccountVerifier.AcountVerifierType.UNKNOWN;

  constructor(data?: PartialMessage<AccountVerifier>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.AccountVerifier";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(AccountVerifier.AcountVerifierType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccountVerifier {
    return new AccountVerifier().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccountVerifier {
    return new AccountVerifier().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccountVerifier {
    return new AccountVerifier().fromJsonString(jsonString, options);
  }

  static equals(a: AccountVerifier | PlainMessage<AccountVerifier> | undefined, b: AccountVerifier | PlainMessage<AccountVerifier> | undefined): boolean {
    return proto3.util.equals(AccountVerifier, a, b);
  }

  static _enums: {
    AcountVerifierType: typeof AccountVerifier.AcountVerifierType,
  };
}

export namespace AccountVerifier {
  /**
   * @generated from enum web.public.AccountVerifier.AcountVerifierType
   */
  export enum AcountVerifierType {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FINICITY = 1;
     */
    FINICITY = 1,

    /**
     * @generated from enum value: PLAID = 2;
     */
    PLAID = 2,

    /**
     * @generated from enum value: PLAID_MANUAL = 3;
     */
    PLAID_MANUAL = 3,
  }
  // Retrieve enum metadata with: proto3.getEnumType(AccountVerifier.AcountVerifierType)
  proto3.util.setEnumType(AccountVerifier.AcountVerifierType, "web.public.AccountVerifier.AcountVerifierType", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FINICITY" },
    { no: 2, name: "PLAID" },
    { no: 3, name: "PLAID_MANUAL" },
  ]);
}

/**
 * @generated from message web.public.ExternalItem
 */
export class ExternalItem extends Message<ExternalItem> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string institution_id = 2;
   */
  institutionId = "";

  /**
   * @generated from field: string institution_name = 3;
   */
  institutionName = "";

  constructor(data?: PartialMessage<ExternalItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ExternalItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "institution_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "institution_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExternalItem {
    return new ExternalItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExternalItem {
    return new ExternalItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExternalItem {
    return new ExternalItem().fromJsonString(jsonString, options);
  }

  static equals(a: ExternalItem | PlainMessage<ExternalItem> | undefined, b: ExternalItem | PlainMessage<ExternalItem> | undefined): boolean {
    return proto3.util.equals(ExternalItem, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.InitiateAccountVerificationRequest
 */
export class InitiateAccountVerificationRequest extends Message<InitiateAccountVerificationRequest> {
  /**
   * This is useful for Plaid manual account verifications
   *
   * @generated from field: string external_bank_account_token = 1;
   */
  externalBankAccountToken = "";

  /**
   * Indicates we are opening verification from an Oauth callback
   *
   * @generated from field: bool callback = 2;
   */
  callback = false;

  /**
   * deprecated in favor of account_types
   *
   * @generated from field: web.public.InitiateAccountVerificationRequest.ProductType product_type = 3;
   */
  productType = InitiateAccountVerificationRequest.ProductType.UNKNOWN_TYPE;

  /**
   * @generated from field: repeated web.public.InitiateAccountVerificationRequest.ProductType product_types = 4;
   */
  productTypes: InitiateAccountVerificationRequest.ProductType[] = [];

  /**
   * @generated from field: repeated web.public.InitiateAccountVerificationRequest.AccountType account_types_filter = 5;
   */
  accountTypesFilter: InitiateAccountVerificationRequest.AccountType[] = [];

  constructor(data?: PartialMessage<InitiateAccountVerificationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.InitiateAccountVerificationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "external_bank_account_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "callback", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "product_type", kind: "enum", T: proto3.getEnumType(InitiateAccountVerificationRequest.ProductType) },
    { no: 4, name: "product_types", kind: "enum", T: proto3.getEnumType(InitiateAccountVerificationRequest.ProductType), repeated: true },
    { no: 5, name: "account_types_filter", kind: "enum", T: proto3.getEnumType(InitiateAccountVerificationRequest.AccountType), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiateAccountVerificationRequest {
    return new InitiateAccountVerificationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiateAccountVerificationRequest {
    return new InitiateAccountVerificationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiateAccountVerificationRequest {
    return new InitiateAccountVerificationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InitiateAccountVerificationRequest | PlainMessage<InitiateAccountVerificationRequest> | undefined, b: InitiateAccountVerificationRequest | PlainMessage<InitiateAccountVerificationRequest> | undefined): boolean {
    return proto3.util.equals(InitiateAccountVerificationRequest, a, b);
  }

  static _enums: {
    ProductType: typeof InitiateAccountVerificationRequest.ProductType,
    AccountType: typeof InitiateAccountVerificationRequest.AccountType,
  };
}

export namespace InitiateAccountVerificationRequest {
  /**
   * @generated from enum web.public.InitiateAccountVerificationRequest.ProductType
   */
  export enum ProductType {
    /**
     * @generated from enum value: UNKNOWN_TYPE = 0;
     */
    UNKNOWN_TYPE = 0,

    /**
     * @generated from enum value: AUTH = 1;
     */
    AUTH = 1,

    /**
     * @generated from enum value: LIABILITIES = 2;
     */
    LIABILITIES = 2,

    /**
     * @generated from enum value: IDENTITY = 3;
     */
    IDENTITY = 3,

    /**
     * @generated from enum value: INVESTMENTS = 4;
     */
    INVESTMENTS = 4,

    /**
     * @generated from enum value: TRANSACTIONS = 5;
     */
    TRANSACTIONS = 5,

    /**
     * @generated from enum value: STATEMENTS = 6;
     */
    STATEMENTS = 6,

    /**
     * @generated from enum value: BALANCE = 7;
     */
    BALANCE = 7,
  }
  // Retrieve enum metadata with: proto3.getEnumType(InitiateAccountVerificationRequest.ProductType)
  proto3.util.setEnumType(InitiateAccountVerificationRequest.ProductType, "web.public.InitiateAccountVerificationRequest.ProductType", [
    { no: 0, name: "UNKNOWN_TYPE" },
    { no: 1, name: "AUTH" },
    { no: 2, name: "LIABILITIES" },
    { no: 3, name: "IDENTITY" },
    { no: 4, name: "INVESTMENTS" },
    { no: 5, name: "TRANSACTIONS" },
    { no: 6, name: "STATEMENTS" },
    { no: 7, name: "BALANCE" },
  ]);
}

export namespace InitiateAccountVerificationRequest {
  /**
   * @generated from enum web.public.InitiateAccountVerificationRequest.AccountType
   */
  export enum AccountType {
    /**
     * @generated from enum value: UNKNOWN_ACCOUNT_TYPE = 0;
     */
    UNKNOWN_ACCOUNT_TYPE = 0,

    /**
     * @generated from enum value: DEPOSITORY = 1;
     */
    DEPOSITORY = 1,

    /**
     * @generated from enum value: CREDIT = 2;
     */
    CREDIT = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(InitiateAccountVerificationRequest.AccountType)
  proto3.util.setEnumType(InitiateAccountVerificationRequest.AccountType, "web.public.InitiateAccountVerificationRequest.AccountType", [
    { no: 0, name: "UNKNOWN_ACCOUNT_TYPE" },
    { no: 1, name: "DEPOSITORY" },
    { no: 2, name: "CREDIT" },
  ]);
}

/**
 * @generated from message web.public.InitiateAccountVerificationResponse
 */
export class InitiateAccountVerificationResponse extends Message<InitiateAccountVerificationResponse> {
  /**
   * @generated from field: web.public.InitiateAccountVerificationResponse.Status status = 1;
   */
  status = InitiateAccountVerificationResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: web.public.AccountVerifier account_verifier = 3;
   */
  accountVerifier?: AccountVerifier;

  /**
   * @generated from field: string verification_link = 4;
   */
  verificationLink = "";

  /**
   * @generated from field: string external_bank_account_token = 5;
   */
  externalBankAccountToken = "";

  /**
   * @generated from field: repeated web.public.ExternalItem institutions_config = 6;
   */
  institutionsConfig: ExternalItem[] = [];

  constructor(data?: PartialMessage<InitiateAccountVerificationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.InitiateAccountVerificationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(InitiateAccountVerificationResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "account_verifier", kind: "message", T: AccountVerifier },
    { no: 4, name: "verification_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "external_bank_account_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "institutions_config", kind: "message", T: ExternalItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiateAccountVerificationResponse {
    return new InitiateAccountVerificationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiateAccountVerificationResponse {
    return new InitiateAccountVerificationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiateAccountVerificationResponse {
    return new InitiateAccountVerificationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitiateAccountVerificationResponse | PlainMessage<InitiateAccountVerificationResponse> | undefined, b: InitiateAccountVerificationResponse | PlainMessage<InitiateAccountVerificationResponse> | undefined): boolean {
    return proto3.util.equals(InitiateAccountVerificationResponse, a, b);
  }

  static _enums: {
    Status: typeof InitiateAccountVerificationResponse.Status,
  };
}

export namespace InitiateAccountVerificationResponse {
  /**
   * @generated from enum web.public.InitiateAccountVerificationResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(InitiateAccountVerificationResponse.Status)
  proto3.util.setEnumType(InitiateAccountVerificationResponse.Status, "web.public.InitiateAccountVerificationResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.CompleteAccountVerificationRequest
 */
export class CompleteAccountVerificationRequest extends Message<CompleteAccountVerificationRequest> {
  /**
   * @generated from field: string external_bank_account_token = 1;
   */
  externalBankAccountToken = "";

  /**
   * @generated from field: string verifier_public_token = 2;
   */
  verifierPublicToken = "";

  /**
   * @generated from field: string metadata = 3;
   */
  metadata = "";

  constructor(data?: PartialMessage<CompleteAccountVerificationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CompleteAccountVerificationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "external_bank_account_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "verifier_public_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "metadata", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompleteAccountVerificationRequest {
    return new CompleteAccountVerificationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompleteAccountVerificationRequest {
    return new CompleteAccountVerificationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompleteAccountVerificationRequest {
    return new CompleteAccountVerificationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CompleteAccountVerificationRequest | PlainMessage<CompleteAccountVerificationRequest> | undefined, b: CompleteAccountVerificationRequest | PlainMessage<CompleteAccountVerificationRequest> | undefined): boolean {
    return proto3.util.equals(CompleteAccountVerificationRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.CompleteAccountVerificationResponse
 */
export class CompleteAccountVerificationResponse extends Message<CompleteAccountVerificationResponse> {
  /**
   * @generated from field: web.public.CompleteAccountVerificationResponse.Status status = 1;
   */
  status = CompleteAccountVerificationResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: string external_bank_account_token = 3;
   */
  externalBankAccountToken = "";

  /**
   * @generated from field: string payment_method_token = 4;
   */
  paymentMethodToken = "";

  constructor(data?: PartialMessage<CompleteAccountVerificationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CompleteAccountVerificationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(CompleteAccountVerificationResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "external_bank_account_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "payment_method_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompleteAccountVerificationResponse {
    return new CompleteAccountVerificationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompleteAccountVerificationResponse {
    return new CompleteAccountVerificationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompleteAccountVerificationResponse {
    return new CompleteAccountVerificationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CompleteAccountVerificationResponse | PlainMessage<CompleteAccountVerificationResponse> | undefined, b: CompleteAccountVerificationResponse | PlainMessage<CompleteAccountVerificationResponse> | undefined): boolean {
    return proto3.util.equals(CompleteAccountVerificationResponse, a, b);
  }

  static _enums: {
    Status: typeof CompleteAccountVerificationResponse.Status,
  };
}

export namespace CompleteAccountVerificationResponse {
  /**
   * @generated from enum web.public.CompleteAccountVerificationResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,

    /**
     * @generated from enum value: INSTITUTION_BLOCKED = 4;
     */
    INSTITUTION_BLOCKED = 4,
  }
  // Retrieve enum metadata with: proto3.getEnumType(CompleteAccountVerificationResponse.Status)
  proto3.util.setEnumType(CompleteAccountVerificationResponse.Status, "web.public.CompleteAccountVerificationResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
    { no: 4, name: "INSTITUTION_BLOCKED" },
  ]);
}

/**
 * @generated from message web.public.CompleteItemVerificationRequest
 */
export class CompleteItemVerificationRequest extends Message<CompleteItemVerificationRequest> {
  /**
   * @generated from field: string external_item_token = 1;
   */
  externalItemToken = "";

  /**
   * @generated from field: string verifier_public_token = 2;
   */
  verifierPublicToken = "";

  /**
   * @generated from field: string metadata = 3;
   */
  metadata = "";

  constructor(data?: PartialMessage<CompleteItemVerificationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CompleteItemVerificationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "external_item_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "verifier_public_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "metadata", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompleteItemVerificationRequest {
    return new CompleteItemVerificationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompleteItemVerificationRequest {
    return new CompleteItemVerificationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompleteItemVerificationRequest {
    return new CompleteItemVerificationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CompleteItemVerificationRequest | PlainMessage<CompleteItemVerificationRequest> | undefined, b: CompleteItemVerificationRequest | PlainMessage<CompleteItemVerificationRequest> | undefined): boolean {
    return proto3.util.equals(CompleteItemVerificationRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.CompleteItemVerificationResponse
 */
export class CompleteItemVerificationResponse extends Message<CompleteItemVerificationResponse> {
  /**
   * @generated from field: web.public.CompleteItemVerificationResponse.Status status = 1;
   */
  status = CompleteItemVerificationResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: string external_bank_account_token = 3;
   */
  externalBankAccountToken = "";

  /**
   * @generated from field: string payment_method_token = 4;
   */
  paymentMethodToken = "";

  /**
   * @generated from field: optional web.public.ExternalItem existing_external_item = 5;
   */
  existingExternalItem?: ExternalItem;

  constructor(data?: PartialMessage<CompleteItemVerificationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CompleteItemVerificationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(CompleteItemVerificationResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "external_bank_account_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "payment_method_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "existing_external_item", kind: "message", T: ExternalItem, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompleteItemVerificationResponse {
    return new CompleteItemVerificationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompleteItemVerificationResponse {
    return new CompleteItemVerificationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompleteItemVerificationResponse {
    return new CompleteItemVerificationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CompleteItemVerificationResponse | PlainMessage<CompleteItemVerificationResponse> | undefined, b: CompleteItemVerificationResponse | PlainMessage<CompleteItemVerificationResponse> | undefined): boolean {
    return proto3.util.equals(CompleteItemVerificationResponse, a, b);
  }

  static _enums: {
    Status: typeof CompleteItemVerificationResponse.Status,
  };
}

export namespace CompleteItemVerificationResponse {
  /**
   * @generated from enum web.public.CompleteItemVerificationResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,

    /**
     * @generated from enum value: INSTITUTION_BLOCKED = 4;
     */
    INSTITUTION_BLOCKED = 4,

    /**
     * @generated from enum value: REDIRECT_TO_UPDATE = 5;
     */
    REDIRECT_TO_UPDATE = 5,
  }
  // Retrieve enum metadata with: proto3.getEnumType(CompleteItemVerificationResponse.Status)
  proto3.util.setEnumType(CompleteItemVerificationResponse.Status, "web.public.CompleteItemVerificationResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
    { no: 4, name: "INSTITUTION_BLOCKED" },
    { no: 5, name: "REDIRECT_TO_UPDATE" },
  ]);
}

/**
 * @generated from message web.public.ExternalBankAccountAction
 */
export class ExternalBankAccountAction extends Message<ExternalBankAccountAction> {
  /**
   * @generated from oneof web.public.ExternalBankAccountAction.action
   */
  action: {
    /**
     * @generated from field: web.public.EmptyAction empty = 1;
     */
    value: EmptyAction;
    case: "empty";
  } | {
    /**
     * @generated from field: web.public.AuthenticationAction authentication = 2;
     */
    value: AuthenticationAction;
    case: "authentication";
  } | {
    /**
     * @generated from field: web.public.ManualAccountVerificationAction manual_account_verification = 3;
     */
    value: ManualAccountVerificationAction;
    case: "manualAccountVerification";
  } | {
    /**
     * @generated from field: web.public.PersonaAccountVerificationAction persona_account_verification = 4;
     */
    value: PersonaAccountVerificationAction;
    case: "personaAccountVerification";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ExternalBankAccountAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ExternalBankAccountAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "empty", kind: "message", T: EmptyAction, oneof: "action" },
    { no: 2, name: "authentication", kind: "message", T: AuthenticationAction, oneof: "action" },
    { no: 3, name: "manual_account_verification", kind: "message", T: ManualAccountVerificationAction, oneof: "action" },
    { no: 4, name: "persona_account_verification", kind: "message", T: PersonaAccountVerificationAction, oneof: "action" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExternalBankAccountAction {
    return new ExternalBankAccountAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExternalBankAccountAction {
    return new ExternalBankAccountAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExternalBankAccountAction {
    return new ExternalBankAccountAction().fromJsonString(jsonString, options);
  }

  static equals(a: ExternalBankAccountAction | PlainMessage<ExternalBankAccountAction> | undefined, b: ExternalBankAccountAction | PlainMessage<ExternalBankAccountAction> | undefined): boolean {
    return proto3.util.equals(ExternalBankAccountAction, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.EmptyAction
 */
export class EmptyAction extends Message<EmptyAction> {
  constructor(data?: PartialMessage<EmptyAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.EmptyAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EmptyAction {
    return new EmptyAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EmptyAction {
    return new EmptyAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EmptyAction {
    return new EmptyAction().fromJsonString(jsonString, options);
  }

  static equals(a: EmptyAction | PlainMessage<EmptyAction> | undefined, b: EmptyAction | PlainMessage<EmptyAction> | undefined): boolean {
    return proto3.util.equals(EmptyAction, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.AuthenticationAction
 */
export class AuthenticationAction extends Message<AuthenticationAction> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string body = 2;
   */
  body = "";

  /**
   * @generated from field: string cta_text = 3;
   */
  ctaText = "";

  constructor(data?: PartialMessage<AuthenticationAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.AuthenticationAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cta_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuthenticationAction {
    return new AuthenticationAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuthenticationAction {
    return new AuthenticationAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuthenticationAction {
    return new AuthenticationAction().fromJsonString(jsonString, options);
  }

  static equals(a: AuthenticationAction | PlainMessage<AuthenticationAction> | undefined, b: AuthenticationAction | PlainMessage<AuthenticationAction> | undefined): boolean {
    return proto3.util.equals(AuthenticationAction, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.ManualAccountVerificationAction
 */
export class ManualAccountVerificationAction extends Message<ManualAccountVerificationAction> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string body = 2;
   */
  body = "";

  /**
   * @generated from field: string cta_text = 3;
   */
  ctaText = "";

  constructor(data?: PartialMessage<ManualAccountVerificationAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ManualAccountVerificationAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cta_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ManualAccountVerificationAction {
    return new ManualAccountVerificationAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ManualAccountVerificationAction {
    return new ManualAccountVerificationAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ManualAccountVerificationAction {
    return new ManualAccountVerificationAction().fromJsonString(jsonString, options);
  }

  static equals(a: ManualAccountVerificationAction | PlainMessage<ManualAccountVerificationAction> | undefined, b: ManualAccountVerificationAction | PlainMessage<ManualAccountVerificationAction> | undefined): boolean {
    return proto3.util.equals(ManualAccountVerificationAction, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.PersonaAccountVerificationAction
 */
export class PersonaAccountVerificationAction extends Message<PersonaAccountVerificationAction> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string body = 2;
   */
  body = "";

  /**
   * @generated from field: string cta_text = 3;
   */
  ctaText = "";

  /**
   * @generated from field: string persona_verification_url = 4;
   */
  personaVerificationUrl = "";

  constructor(data?: PartialMessage<PersonaAccountVerificationAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PersonaAccountVerificationAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cta_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "persona_verification_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PersonaAccountVerificationAction {
    return new PersonaAccountVerificationAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PersonaAccountVerificationAction {
    return new PersonaAccountVerificationAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PersonaAccountVerificationAction {
    return new PersonaAccountVerificationAction().fromJsonString(jsonString, options);
  }

  static equals(a: PersonaAccountVerificationAction | PlainMessage<PersonaAccountVerificationAction> | undefined, b: PersonaAccountVerificationAction | PlainMessage<PersonaAccountVerificationAction> | undefined): boolean {
    return proto3.util.equals(PersonaAccountVerificationAction, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.Payday
 */
export class Payday extends Message<Payday> {
  /**
   * @generated from field: google.protobuf.Timestamp date = 1;
   */
  date?: Timestamp;

  constructor(data?: PartialMessage<Payday>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.Payday";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Payday {
    return new Payday().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Payday {
    return new Payday().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Payday {
    return new Payday().fromJsonString(jsonString, options);
  }

  static equals(a: Payday | PlainMessage<Payday> | undefined, b: Payday | PlainMessage<Payday> | undefined): boolean {
    return proto3.util.equals(Payday, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.PaycheckDetails
 */
export class PaycheckDetails extends Message<PaycheckDetails> {
  /**
   * @generated from field: string description = 1;
   */
  description = "";

  /**
   * @generated from field: web.public.PaycheckDetails.PaycheckRecurrence recurrence = 2;
   */
  recurrence = PaycheckDetails.PaycheckRecurrence.UNKNOWN;

  /**
   * @generated from field: web.public.Money estimated_amount = 3;
   */
  estimatedAmount?: Money;

  /**
   * @generated from field: repeated web.public.Payday upcoming_paychecks = 4;
   */
  upcomingPaychecks: Payday[] = [];

  constructor(data?: PartialMessage<PaycheckDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PaycheckDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "recurrence", kind: "enum", T: proto3.getEnumType(PaycheckDetails.PaycheckRecurrence) },
    { no: 3, name: "estimated_amount", kind: "message", T: Money },
    { no: 4, name: "upcoming_paychecks", kind: "message", T: Payday, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaycheckDetails {
    return new PaycheckDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaycheckDetails {
    return new PaycheckDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaycheckDetails {
    return new PaycheckDetails().fromJsonString(jsonString, options);
  }

  static equals(a: PaycheckDetails | PlainMessage<PaycheckDetails> | undefined, b: PaycheckDetails | PlainMessage<PaycheckDetails> | undefined): boolean {
    return proto3.util.equals(PaycheckDetails, a, b);
  }

  static _enums: {
    PaycheckRecurrence: typeof PaycheckDetails.PaycheckRecurrence,
  };
}

export namespace PaycheckDetails {
  /**
   * @generated from enum web.public.PaycheckDetails.PaycheckRecurrence
   */
  export enum PaycheckRecurrence {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: WEEKLY = 1;
     */
    WEEKLY = 1,

    /**
     * @generated from enum value: BI_WEEKLY = 2;
     */
    BI_WEEKLY = 2,

    /**
     * @generated from enum value: BI_MONTHLY = 3;
     */
    BI_MONTHLY = 3,

    /**
     * @generated from enum value: MONTHLY = 4;
     */
    MONTHLY = 4,

    /**
     * @generated from enum value: QUARTERLY = 5;
     */
    QUARTERLY = 5,

    /**
     * @generated from enum value: SEMI_YEARLY = 6;
     */
    SEMI_YEARLY = 6,

    /**
     * @generated from enum value: YEARLY = 7;
     */
    YEARLY = 7,

    /**
     * @generated from enum value: HYBRID = 8;
     */
    HYBRID = 8,

    /**
     * @generated from enum value: OTHER = 9;
     */
    OTHER = 9,

    /**
     * @generated from enum value: QUAD_WEEKLY = 10;
     */
    QUAD_WEEKLY = 10,
  }
  // Retrieve enum metadata with: proto3.getEnumType(PaycheckDetails.PaycheckRecurrence)
  proto3.util.setEnumType(PaycheckDetails.PaycheckRecurrence, "web.public.PaycheckDetails.PaycheckRecurrence", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "WEEKLY" },
    { no: 2, name: "BI_WEEKLY" },
    { no: 3, name: "BI_MONTHLY" },
    { no: 4, name: "MONTHLY" },
    { no: 5, name: "QUARTERLY" },
    { no: 6, name: "SEMI_YEARLY" },
    { no: 7, name: "YEARLY" },
    { no: 8, name: "HYBRID" },
    { no: 9, name: "OTHER" },
    { no: 10, name: "QUAD_WEEKLY" },
  ]);
}

/**
 * @generated from message web.public.PaycheckError
 */
export class PaycheckError extends Message<PaycheckError> {
  /**
   * @generated from field: web.public.Error error = 1;
   */
  error?: Error;

  /**
   * @generated from field: string cta_text = 2;
   */
  ctaText = "";

  /**
   * @generated from field: string cta_url = 3;
   */
  ctaUrl = "";

  constructor(data?: PartialMessage<PaycheckError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PaycheckError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error", kind: "message", T: Error },
    { no: 2, name: "cta_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cta_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaycheckError {
    return new PaycheckError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaycheckError {
    return new PaycheckError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaycheckError {
    return new PaycheckError().fromJsonString(jsonString, options);
  }

  static equals(a: PaycheckError | PlainMessage<PaycheckError> | undefined, b: PaycheckError | PlainMessage<PaycheckError> | undefined): boolean {
    return proto3.util.equals(PaycheckError, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.ExternalBankAccount
 */
export class ExternalBankAccount extends Message<ExternalBankAccount> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: web.public.AccountVerifier account_verifier = 2;
   */
  accountVerifier?: AccountVerifier;

  /**
   * @generated from field: map<string, string> metadata = 3;
   */
  metadata: { [key: string]: string } = {};

  /**
   * {name} - {last_four}
   *
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: repeated web.public.ExternalBankAccount.Usage uses = 5;
   */
  uses: ExternalBankAccount.Usage[] = [];

  /**
   * @generated from field: web.public.ExternalBankAccount.Status status = 6;
   */
  status = ExternalBankAccount.Status.UNVERIFIED;

  /**
   * @generated from field: repeated web.public.ExternalBankAccountAction actions = 7;
   */
  actions: ExternalBankAccountAction[] = [];

  /**
   * @generated from field: string institution_name = 8;
   */
  institutionName = "";

  /**
   * checking / savings
   *
   * @generated from field: string subtype = 9;
   */
  subtype = "";

  /**
   * @generated from field: string last_four = 10;
   */
  lastFour = "";

  /**
   * @generated from field: optional web.public.PaycheckDetails paycheck_details = 11;
   */
  paycheckDetails?: PaycheckDetails;

  /**
   * @generated from field: optional web.public.Money current_balance = 12;
   */
  currentBalance?: Money;

  /**
   * @generated from field: repeated string debit_card_payment_method_tokens = 13;
   */
  debitCardPaymentMethodTokens: string[] = [];

  /**
   * @generated from field: optional web.public.PaycheckError paycheck_error = 14;
   */
  paycheckError?: PaycheckError;

  /**
   * @generated from field: optional bytes institution_logo = 15;
   */
  institutionLogo?: Uint8Array;

  constructor(data?: PartialMessage<ExternalBankAccount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ExternalBankAccount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_verifier", kind: "message", T: AccountVerifier },
    { no: 3, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "uses", kind: "enum", T: proto3.getEnumType(ExternalBankAccount.Usage), repeated: true },
    { no: 6, name: "status", kind: "enum", T: proto3.getEnumType(ExternalBankAccount.Status) },
    { no: 7, name: "actions", kind: "message", T: ExternalBankAccountAction, repeated: true },
    { no: 8, name: "institution_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "subtype", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "last_four", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "paycheck_details", kind: "message", T: PaycheckDetails, opt: true },
    { no: 12, name: "current_balance", kind: "message", T: Money, opt: true },
    { no: 13, name: "debit_card_payment_method_tokens", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "paycheck_error", kind: "message", T: PaycheckError, opt: true },
    { no: 15, name: "institution_logo", kind: "scalar", T: 12 /* ScalarType.BYTES */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExternalBankAccount {
    return new ExternalBankAccount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExternalBankAccount {
    return new ExternalBankAccount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExternalBankAccount {
    return new ExternalBankAccount().fromJsonString(jsonString, options);
  }

  static equals(a: ExternalBankAccount | PlainMessage<ExternalBankAccount> | undefined, b: ExternalBankAccount | PlainMessage<ExternalBankAccount> | undefined): boolean {
    return proto3.util.equals(ExternalBankAccount, a, b);
  }

  static _enums: {
    Usage: typeof ExternalBankAccount.Usage,
    Status: typeof ExternalBankAccount.Status,
  };
}

export namespace ExternalBankAccount {
  /**
   * @generated from enum web.public.ExternalBankAccount.Usage
   */
  export enum Usage {
    /**
     * @generated from enum value: DEFAULT = 0;
     */
    DEFAULT = 0,

    /**
     * @generated from enum value: AUTOPAY = 1;
     */
    AUTOPAY = 1,
  }
  // Retrieve enum metadata with: proto3.getEnumType(ExternalBankAccount.Usage)
  proto3.util.setEnumType(ExternalBankAccount.Usage, "web.public.ExternalBankAccount.Usage", [
    { no: 0, name: "DEFAULT" },
    { no: 1, name: "AUTOPAY" },
  ]);
}

export namespace ExternalBankAccount {
  /**
   * @generated from enum web.public.ExternalBankAccount.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNVERIFIED = 0;
     */
    UNVERIFIED = 0,

    /**
     * @generated from enum value: VERIFIED = 1;
     */
    VERIFIED = 1,

    /**
     * @generated from enum value: PENDING_MANUAL_VERIFICATION = 2;
     */
    PENDING_MANUAL_VERIFICATION = 2,

    /**
     * @generated from enum value: MANUALLY_VERIFIED = 3;
     */
    MANUALLY_VERIFIED = 3,

    /**
     * @generated from enum value: PENDING_AUTOMATIC_VERIFICATION = 4;
     */
    PENDING_AUTOMATIC_VERIFICATION = 4,

    /**
     * @generated from enum value: INACTIVE = 5;
     */
    INACTIVE = 5,

    /**
     * @generated from enum value: VERIFICATION_EXPIRED = 6;
     */
    VERIFICATION_EXPIRED = 6,

    /**
     * @generated from enum value: VERIFICATION_FAILED = 7;
     */
    VERIFICATION_FAILED = 7,

    /**
     * @generated from enum value: BLOCKED = 8;
     */
    BLOCKED = 8,
  }
  // Retrieve enum metadata with: proto3.getEnumType(ExternalBankAccount.Status)
  proto3.util.setEnumType(ExternalBankAccount.Status, "web.public.ExternalBankAccount.Status", [
    { no: 0, name: "UNVERIFIED" },
    { no: 1, name: "VERIFIED" },
    { no: 2, name: "PENDING_MANUAL_VERIFICATION" },
    { no: 3, name: "MANUALLY_VERIFIED" },
    { no: 4, name: "PENDING_AUTOMATIC_VERIFICATION" },
    { no: 5, name: "INACTIVE" },
    { no: 6, name: "VERIFICATION_EXPIRED" },
    { no: 7, name: "VERIFICATION_FAILED" },
    { no: 8, name: "BLOCKED" },
  ]);
}

/**
 * @generated from message web.public.ListAccountsRequest
 */
export class ListAccountsRequest extends Message<ListAccountsRequest> {
  /**
   * @generated from field: web.public.ListAccountsRequest.Scope scope = 1;
   */
  scope = ListAccountsRequest.Scope.UNKNOWN;

  constructor(data?: PartialMessage<ListAccountsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ListAccountsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scope", kind: "enum", T: proto3.getEnumType(ListAccountsRequest.Scope) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAccountsRequest {
    return new ListAccountsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAccountsRequest {
    return new ListAccountsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAccountsRequest {
    return new ListAccountsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAccountsRequest | PlainMessage<ListAccountsRequest> | undefined, b: ListAccountsRequest | PlainMessage<ListAccountsRequest> | undefined): boolean {
    return proto3.util.equals(ListAccountsRequest, a, b);
  }

  static _enums: {
    Scope: typeof ListAccountsRequest.Scope,
  };
}

export namespace ListAccountsRequest {
  /**
   * @generated from enum web.public.ListAccountsRequest.Scope
   */
  export enum Scope {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: DEFAULT = 1;
     */
    DEFAULT = 1,

    /**
     * @generated from enum value: PAYCHECK = 2;
     */
    PAYCHECK = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(ListAccountsRequest.Scope)
  proto3.util.setEnumType(ListAccountsRequest.Scope, "web.public.ListAccountsRequest.Scope", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "DEFAULT" },
    { no: 2, name: "PAYCHECK" },
  ]);
}

/**
 * @generated from message web.public.ListAccountsResponse
 */
export class ListAccountsResponse extends Message<ListAccountsResponse> {
  /**
   * @generated from field: web.public.ListAccountsResponse.Status status = 1;
   */
  status = ListAccountsResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: repeated web.public.ExternalBankAccount external_bank_accounts = 3;
   */
  externalBankAccounts: ExternalBankAccount[] = [];

  /**
   * @generated from field: optional string add_account_url = 4;
   */
  addAccountUrl?: string;

  constructor(data?: PartialMessage<ListAccountsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ListAccountsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(ListAccountsResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "external_bank_accounts", kind: "message", T: ExternalBankAccount, repeated: true },
    { no: 4, name: "add_account_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAccountsResponse {
    return new ListAccountsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAccountsResponse {
    return new ListAccountsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAccountsResponse {
    return new ListAccountsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAccountsResponse | PlainMessage<ListAccountsResponse> | undefined, b: ListAccountsResponse | PlainMessage<ListAccountsResponse> | undefined): boolean {
    return proto3.util.equals(ListAccountsResponse, a, b);
  }

  static _enums: {
    Status: typeof ListAccountsResponse.Status,
  };
}

export namespace ListAccountsResponse {
  /**
   * @generated from enum web.public.ListAccountsResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(ListAccountsResponse.Status)
  proto3.util.setEnumType(ListAccountsResponse.Status, "web.public.ListAccountsResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.ListBalanceAccountsRequest
 */
export class ListBalanceAccountsRequest extends Message<ListBalanceAccountsRequest> {
  /**
   * @generated from field: web.public.ListBalanceAccountsRequest.Scope scope = 1;
   */
  scope = ListBalanceAccountsRequest.Scope.UNKNOWN;

  constructor(data?: PartialMessage<ListBalanceAccountsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ListBalanceAccountsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scope", kind: "enum", T: proto3.getEnumType(ListBalanceAccountsRequest.Scope) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListBalanceAccountsRequest {
    return new ListBalanceAccountsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListBalanceAccountsRequest {
    return new ListBalanceAccountsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListBalanceAccountsRequest {
    return new ListBalanceAccountsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListBalanceAccountsRequest | PlainMessage<ListBalanceAccountsRequest> | undefined, b: ListBalanceAccountsRequest | PlainMessage<ListBalanceAccountsRequest> | undefined): boolean {
    return proto3.util.equals(ListBalanceAccountsRequest, a, b);
  }

  static _enums: {
    Scope: typeof ListBalanceAccountsRequest.Scope,
  };
}

export namespace ListBalanceAccountsRequest {
  /**
   * @generated from enum web.public.ListBalanceAccountsRequest.Scope
   */
  export enum Scope {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: DEFAULT = 1;
     */
    DEFAULT = 1,

    /**
     * @generated from enum value: PAYCHECK = 2;
     */
    PAYCHECK = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(ListBalanceAccountsRequest.Scope)
  proto3.util.setEnumType(ListBalanceAccountsRequest.Scope, "web.public.ListBalanceAccountsRequest.Scope", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "DEFAULT" },
    { no: 2, name: "PAYCHECK" },
  ]);
}

/**
 * @generated from message web.public.ListBalanceAccountsResponse
 */
export class ListBalanceAccountsResponse extends Message<ListBalanceAccountsResponse> {
  /**
   * @generated from field: web.public.ListBalanceAccountsResponse.Status status = 1;
   */
  status = ListBalanceAccountsResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: repeated web.public.ExternalBankAccount external_bank_accounts = 3;
   */
  externalBankAccounts: ExternalBankAccount[] = [];

  constructor(data?: PartialMessage<ListBalanceAccountsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ListBalanceAccountsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(ListBalanceAccountsResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "external_bank_accounts", kind: "message", T: ExternalBankAccount, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListBalanceAccountsResponse {
    return new ListBalanceAccountsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListBalanceAccountsResponse {
    return new ListBalanceAccountsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListBalanceAccountsResponse {
    return new ListBalanceAccountsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListBalanceAccountsResponse | PlainMessage<ListBalanceAccountsResponse> | undefined, b: ListBalanceAccountsResponse | PlainMessage<ListBalanceAccountsResponse> | undefined): boolean {
    return proto3.util.equals(ListBalanceAccountsResponse, a, b);
  }

  static _enums: {
    Status: typeof ListBalanceAccountsResponse.Status,
  };
}

export namespace ListBalanceAccountsResponse {
  /**
   * @generated from enum web.public.ListBalanceAccountsResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(ListBalanceAccountsResponse.Status)
  proto3.util.setEnumType(ListBalanceAccountsResponse.Status, "web.public.ListBalanceAccountsResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.DeactivateAccountRequest
 */
export class DeactivateAccountRequest extends Message<DeactivateAccountRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<DeactivateAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.DeactivateAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeactivateAccountRequest {
    return new DeactivateAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeactivateAccountRequest {
    return new DeactivateAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeactivateAccountRequest {
    return new DeactivateAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeactivateAccountRequest | PlainMessage<DeactivateAccountRequest> | undefined, b: DeactivateAccountRequest | PlainMessage<DeactivateAccountRequest> | undefined): boolean {
    return proto3.util.equals(DeactivateAccountRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.DeactivateAccountResponse
 */
export class DeactivateAccountResponse extends Message<DeactivateAccountResponse> {
  /**
   * @generated from field: web.public.DeactivateAccountResponse.Status status = 1;
   */
  status = DeactivateAccountResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<DeactivateAccountResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.DeactivateAccountResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(DeactivateAccountResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeactivateAccountResponse {
    return new DeactivateAccountResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeactivateAccountResponse {
    return new DeactivateAccountResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeactivateAccountResponse {
    return new DeactivateAccountResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeactivateAccountResponse | PlainMessage<DeactivateAccountResponse> | undefined, b: DeactivateAccountResponse | PlainMessage<DeactivateAccountResponse> | undefined): boolean {
    return proto3.util.equals(DeactivateAccountResponse, a, b);
  }

  static _enums: {
    Status: typeof DeactivateAccountResponse.Status,
  };
}

export namespace DeactivateAccountResponse {
  /**
   * @generated from enum web.public.DeactivateAccountResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(DeactivateAccountResponse.Status)
  proto3.util.setEnumType(DeactivateAccountResponse.Status, "web.public.DeactivateAccountResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.GetPersonaVerificationUrlRequest
 */
export class GetPersonaVerificationUrlRequest extends Message<GetPersonaVerificationUrlRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<GetPersonaVerificationUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetPersonaVerificationUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPersonaVerificationUrlRequest {
    return new GetPersonaVerificationUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPersonaVerificationUrlRequest {
    return new GetPersonaVerificationUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPersonaVerificationUrlRequest {
    return new GetPersonaVerificationUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPersonaVerificationUrlRequest | PlainMessage<GetPersonaVerificationUrlRequest> | undefined, b: GetPersonaVerificationUrlRequest | PlainMessage<GetPersonaVerificationUrlRequest> | undefined): boolean {
    return proto3.util.equals(GetPersonaVerificationUrlRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetPersonaVerificationUrlResponse
 */
export class GetPersonaVerificationUrlResponse extends Message<GetPersonaVerificationUrlResponse> {
  /**
   * @generated from field: web.public.GetPersonaVerificationUrlResponse.Status status = 1;
   */
  status = GetPersonaVerificationUrlResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: string persona_verification_url = 3;
   */
  personaVerificationUrl = "";

  constructor(data?: PartialMessage<GetPersonaVerificationUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetPersonaVerificationUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(GetPersonaVerificationUrlResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "persona_verification_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPersonaVerificationUrlResponse {
    return new GetPersonaVerificationUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPersonaVerificationUrlResponse {
    return new GetPersonaVerificationUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPersonaVerificationUrlResponse {
    return new GetPersonaVerificationUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPersonaVerificationUrlResponse | PlainMessage<GetPersonaVerificationUrlResponse> | undefined, b: GetPersonaVerificationUrlResponse | PlainMessage<GetPersonaVerificationUrlResponse> | undefined): boolean {
    return proto3.util.equals(GetPersonaVerificationUrlResponse, a, b);
  }

  static _enums: {
    Status: typeof GetPersonaVerificationUrlResponse.Status,
  };
}

export namespace GetPersonaVerificationUrlResponse {
  /**
   * @generated from enum web.public.GetPersonaVerificationUrlResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(GetPersonaVerificationUrlResponse.Status)
  proto3.util.setEnumType(GetPersonaVerificationUrlResponse.Status, "web.public.GetPersonaVerificationUrlResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.ExternalTransaction
 */
export class ExternalTransaction extends Message<ExternalTransaction> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: uint32 amount_cents = 2;
   */
  amountCents = 0;

  /**
   * @generated from field: string destination = 3;
   */
  destination = "";

  /**
   * @generated from field: google.protobuf.Timestamp transacted_at = 4;
   */
  transactedAt?: Timestamp;

  /**
   * @generated from field: string logo_url = 5;
   */
  logoUrl = "";

  /**
   * @generated from field: web.public.ExternalTransaction.Status status = 6;
   */
  status = ExternalTransaction.Status.UNKNOWN;

  /**
   * @generated from field: int32 incoming_amount_cents = 7;
   */
  incomingAmountCents = 0;

  /**
   * @generated from field: string institution_name = 8;
   */
  institutionName = "";

  /**
   * @generated from field: web.public.ExternalTransaction.Location location = 9;
   */
  location?: ExternalTransaction.Location;

  constructor(data?: PartialMessage<ExternalTransaction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ExternalTransaction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "destination", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "transacted_at", kind: "message", T: Timestamp },
    { no: 5, name: "logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "status", kind: "enum", T: proto3.getEnumType(ExternalTransaction.Status) },
    { no: 7, name: "incoming_amount_cents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "institution_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "location", kind: "message", T: ExternalTransaction.Location },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExternalTransaction {
    return new ExternalTransaction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExternalTransaction {
    return new ExternalTransaction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExternalTransaction {
    return new ExternalTransaction().fromJsonString(jsonString, options);
  }

  static equals(a: ExternalTransaction | PlainMessage<ExternalTransaction> | undefined, b: ExternalTransaction | PlainMessage<ExternalTransaction> | undefined): boolean {
    return proto3.util.equals(ExternalTransaction, a, b);
  }

  static _enums: {
    Status: typeof ExternalTransaction.Status,
  };
}

export namespace ExternalTransaction {
  /**
   * @generated from enum web.public.ExternalTransaction.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: INACTIVE = 1;
     */
    INACTIVE = 1,

    /**
     * @generated from enum value: PENDING = 2;
     */
    PENDING = 2,

    /**
     * @generated from enum value: COMPLETED = 3;
     */
    COMPLETED = 3,
  }
  // Retrieve enum metadata with: proto3.getEnumType(ExternalTransaction.Status)
  proto3.util.setEnumType(ExternalTransaction.Status, "web.public.ExternalTransaction.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "INACTIVE" },
    { no: 2, name: "PENDING" },
    { no: 3, name: "COMPLETED" },
  ]);
}

export namespace ExternalTransaction {
  /**
   * @generated from message web.public.ExternalTransaction.Location
   */
  export class Location extends Message<ExternalTransaction.Location> {
    /**
     * @generated from field: string city = 1;
     */
    city = "";

    /**
     * @generated from field: string state = 2;
     */
    state = "";

    constructor(data?: PartialMessage<ExternalTransaction.Location>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.ExternalTransaction.Location";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 2, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExternalTransaction.Location {
      return new ExternalTransaction.Location().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExternalTransaction.Location {
      return new ExternalTransaction.Location().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExternalTransaction.Location {
      return new ExternalTransaction.Location().fromJsonString(jsonString, options);
    }

    static equals(a: ExternalTransaction.Location | PlainMessage<ExternalTransaction.Location> | undefined, b: ExternalTransaction.Location | PlainMessage<ExternalTransaction.Location> | undefined): boolean {
      return proto3.util.equals(ExternalTransaction.Location, a, b);
    }

    static _enums: {
    };
  }
}

/**
 * @generated from message web.public.IncomeSource
 */
export class IncomeSource extends Message<IncomeSource> {
  /**
   * @generated from field: web.public.IncomeSource.Status status = 1;
   */
  status = IncomeSource.Status.ACTIVE;

  /**
   * @generated from field: string income_type = 2;
   */
  incomeType = "";

  /**
   * @generated from field: string currency = 3;
   */
  currency = "";

  /**
   * @generated from field: web.public.IncomeSource.Frequency frequency = 4;
   */
  frequency = IncomeSource.Frequency.UNKNOWN;

  /**
   * @generated from field: google.protobuf.Timestamp first_payment_at = 5;
   */
  firstPaymentAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp latest_payment_at = 6;
   */
  latestPaymentAt?: Timestamp;

  /**
   * @generated from field: string latest_payment_description = 7;
   */
  latestPaymentDescription = "";

  /**
   * @generated from field: web.public.Money latest_payment_amount = 8;
   */
  latestPaymentAmount?: Money;

  /**
   * @generated from field: google.protobuf.Timestamp next_payment_at = 9;
   */
  nextPaymentAt?: Timestamp;

  /**
   * @generated from field: web.public.Money next_payment_amount = 10;
   */
  nextPaymentAmount?: Money;

  /**
   * @generated from field: web.public.Money total_amount = 11;
   */
  totalAmount?: Money;

  /**
   * @generated from field: repeated web.public.ExternalTransaction external_transactions = 12;
   */
  externalTransactions: ExternalTransaction[] = [];

  constructor(data?: PartialMessage<IncomeSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.IncomeSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(IncomeSource.Status) },
    { no: 2, name: "income_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "frequency", kind: "enum", T: proto3.getEnumType(IncomeSource.Frequency) },
    { no: 5, name: "first_payment_at", kind: "message", T: Timestamp },
    { no: 6, name: "latest_payment_at", kind: "message", T: Timestamp },
    { no: 7, name: "latest_payment_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "latest_payment_amount", kind: "message", T: Money },
    { no: 9, name: "next_payment_at", kind: "message", T: Timestamp },
    { no: 10, name: "next_payment_amount", kind: "message", T: Money },
    { no: 11, name: "total_amount", kind: "message", T: Money },
    { no: 12, name: "external_transactions", kind: "message", T: ExternalTransaction, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IncomeSource {
    return new IncomeSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IncomeSource {
    return new IncomeSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IncomeSource {
    return new IncomeSource().fromJsonString(jsonString, options);
  }

  static equals(a: IncomeSource | PlainMessage<IncomeSource> | undefined, b: IncomeSource | PlainMessage<IncomeSource> | undefined): boolean {
    return proto3.util.equals(IncomeSource, a, b);
  }

  static _enums: {
    Status: typeof IncomeSource.Status,
    Frequency: typeof IncomeSource.Frequency,
  };
}

export namespace IncomeSource {
  /**
   * @generated from enum web.public.IncomeSource.Status
   */
  export enum Status {
    /**
     * @generated from enum value: ACTIVE = 0;
     */
    ACTIVE = 0,

    /**
     * @generated from enum value: INACTIVE = 1;
     */
    INACTIVE = 1,
  }
  // Retrieve enum metadata with: proto3.getEnumType(IncomeSource.Status)
  proto3.util.setEnumType(IncomeSource.Status, "web.public.IncomeSource.Status", [
    { no: 0, name: "ACTIVE" },
    { no: 1, name: "INACTIVE" },
  ]);
}

export namespace IncomeSource {
  /**
   * @generated from enum web.public.IncomeSource.Frequency
   */
  export enum Frequency {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: DAILY = 1;
     */
    DAILY = 1,

    /**
     * @generated from enum value: WEEKLY = 2;
     */
    WEEKLY = 2,

    /**
     * @generated from enum value: BI_WEEKLY = 3;
     */
    BI_WEEKLY = 3,

    /**
     * @generated from enum value: SEMI_MONTHLY = 4;
     */
    SEMI_MONTHLY = 4,

    /**
     * @generated from enum value: QUAD_WEEKLY = 5;
     */
    QUAD_WEEKLY = 5,

    /**
     * @generated from enum value: MONTHLY = 6;
     */
    MONTHLY = 6,

    /**
     * @generated from enum value: BI_MONTHLY = 7;
     */
    BI_MONTHLY = 7,

    /**
     * @generated from enum value: QUARTERLY = 8;
     */
    QUARTERLY = 8,

    /**
     * @generated from enum value: SEMI_YEARLY = 9;
     */
    SEMI_YEARLY = 9,

    /**
     * @generated from enum value: YEARLY = 10;
     */
    YEARLY = 10,
  }
  // Retrieve enum metadata with: proto3.getEnumType(IncomeSource.Frequency)
  proto3.util.setEnumType(IncomeSource.Frequency, "web.public.IncomeSource.Frequency", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "DAILY" },
    { no: 2, name: "WEEKLY" },
    { no: 3, name: "BI_WEEKLY" },
    { no: 4, name: "SEMI_MONTHLY" },
    { no: 5, name: "QUAD_WEEKLY" },
    { no: 6, name: "MONTHLY" },
    { no: 7, name: "BI_MONTHLY" },
    { no: 8, name: "QUARTERLY" },
    { no: 9, name: "SEMI_YEARLY" },
    { no: 10, name: "YEARLY" },
  ]);
}

/**
 * @generated from message web.public.GetIncomeSnapshotRequest
 */
export class GetIncomeSnapshotRequest extends Message<GetIncomeSnapshotRequest> {
  /**
   * @generated from field: string external_bank_account_token = 1;
   */
  externalBankAccountToken = "";

  constructor(data?: PartialMessage<GetIncomeSnapshotRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetIncomeSnapshotRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "external_bank_account_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetIncomeSnapshotRequest {
    return new GetIncomeSnapshotRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetIncomeSnapshotRequest {
    return new GetIncomeSnapshotRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetIncomeSnapshotRequest {
    return new GetIncomeSnapshotRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetIncomeSnapshotRequest | PlainMessage<GetIncomeSnapshotRequest> | undefined, b: GetIncomeSnapshotRequest | PlainMessage<GetIncomeSnapshotRequest> | undefined): boolean {
    return proto3.util.equals(GetIncomeSnapshotRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.GetIncomeSnapshotResponse
 */
export class GetIncomeSnapshotResponse extends Message<GetIncomeSnapshotResponse> {
  /**
   * @generated from field: web.public.GetIncomeSnapshotResponse.Status status = 1;
   */
  status = GetIncomeSnapshotResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: repeated web.public.IncomeSource income_sources = 3;
   */
  incomeSources: IncomeSource[] = [];

  constructor(data?: PartialMessage<GetIncomeSnapshotResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.GetIncomeSnapshotResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(GetIncomeSnapshotResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "income_sources", kind: "message", T: IncomeSource, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetIncomeSnapshotResponse {
    return new GetIncomeSnapshotResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetIncomeSnapshotResponse {
    return new GetIncomeSnapshotResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetIncomeSnapshotResponse {
    return new GetIncomeSnapshotResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetIncomeSnapshotResponse | PlainMessage<GetIncomeSnapshotResponse> | undefined, b: GetIncomeSnapshotResponse | PlainMessage<GetIncomeSnapshotResponse> | undefined): boolean {
    return proto3.util.equals(GetIncomeSnapshotResponse, a, b);
  }

  static _enums: {
    Status: typeof GetIncomeSnapshotResponse.Status,
  };
}

export namespace GetIncomeSnapshotResponse {
  /**
   * @generated from enum web.public.GetIncomeSnapshotResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(GetIncomeSnapshotResponse.Status)
  proto3.util.setEnumType(GetIncomeSnapshotResponse.Status, "web.public.GetIncomeSnapshotResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.CreateExternalTransactionEnrichmentGroupRequest
 */
export class CreateExternalTransactionEnrichmentGroupRequest extends Message<CreateExternalTransactionEnrichmentGroupRequest> {
  /**
   * @generated from field: string external_bank_account_token = 1;
   */
  externalBankAccountToken = "";

  /**
   * @generated from field: repeated string external_transaction_tokens = 2;
   */
  externalTransactionTokens: string[] = [];

  /**
   * @generated from field: web.public.CreateExternalTransactionEnrichmentGroupRequest.TransactionEnrichmentType type = 3;
   */
  type = CreateExternalTransactionEnrichmentGroupRequest.TransactionEnrichmentType.UNKNOWN;

  constructor(data?: PartialMessage<CreateExternalTransactionEnrichmentGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CreateExternalTransactionEnrichmentGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "external_bank_account_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "external_transaction_tokens", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(CreateExternalTransactionEnrichmentGroupRequest.TransactionEnrichmentType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateExternalTransactionEnrichmentGroupRequest {
    return new CreateExternalTransactionEnrichmentGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateExternalTransactionEnrichmentGroupRequest {
    return new CreateExternalTransactionEnrichmentGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateExternalTransactionEnrichmentGroupRequest {
    return new CreateExternalTransactionEnrichmentGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateExternalTransactionEnrichmentGroupRequest | PlainMessage<CreateExternalTransactionEnrichmentGroupRequest> | undefined, b: CreateExternalTransactionEnrichmentGroupRequest | PlainMessage<CreateExternalTransactionEnrichmentGroupRequest> | undefined): boolean {
    return proto3.util.equals(CreateExternalTransactionEnrichmentGroupRequest, a, b);
  }

  static _enums: {
    TransactionEnrichmentType: typeof CreateExternalTransactionEnrichmentGroupRequest.TransactionEnrichmentType,
  };
}

export namespace CreateExternalTransactionEnrichmentGroupRequest {
  /**
   * @generated from enum web.public.CreateExternalTransactionEnrichmentGroupRequest.TransactionEnrichmentType
   */
  export enum TransactionEnrichmentType {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: NONE = 1;
     */
    NONE = 1,

    /**
     * @generated from enum value: INCOME = 2;
     */
    INCOME = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(CreateExternalTransactionEnrichmentGroupRequest.TransactionEnrichmentType)
  proto3.util.setEnumType(CreateExternalTransactionEnrichmentGroupRequest.TransactionEnrichmentType, "web.public.CreateExternalTransactionEnrichmentGroupRequest.TransactionEnrichmentType", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "NONE" },
    { no: 2, name: "INCOME" },
  ]);
}

/**
 * @generated from message web.public.CreateExternalTransactionEnrichmentGroupResponse
 */
export class CreateExternalTransactionEnrichmentGroupResponse extends Message<CreateExternalTransactionEnrichmentGroupResponse> {
  /**
   * @generated from field: web.public.CreateExternalTransactionEnrichmentGroupResponse.Status status = 1;
   */
  status = CreateExternalTransactionEnrichmentGroupResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<CreateExternalTransactionEnrichmentGroupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CreateExternalTransactionEnrichmentGroupResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(CreateExternalTransactionEnrichmentGroupResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateExternalTransactionEnrichmentGroupResponse {
    return new CreateExternalTransactionEnrichmentGroupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateExternalTransactionEnrichmentGroupResponse {
    return new CreateExternalTransactionEnrichmentGroupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateExternalTransactionEnrichmentGroupResponse {
    return new CreateExternalTransactionEnrichmentGroupResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateExternalTransactionEnrichmentGroupResponse | PlainMessage<CreateExternalTransactionEnrichmentGroupResponse> | undefined, b: CreateExternalTransactionEnrichmentGroupResponse | PlainMessage<CreateExternalTransactionEnrichmentGroupResponse> | undefined): boolean {
    return proto3.util.equals(CreateExternalTransactionEnrichmentGroupResponse, a, b);
  }

  static _enums: {
    Status: typeof CreateExternalTransactionEnrichmentGroupResponse.Status,
  };
}

export namespace CreateExternalTransactionEnrichmentGroupResponse {
  /**
   * @generated from enum web.public.CreateExternalTransactionEnrichmentGroupResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(CreateExternalTransactionEnrichmentGroupResponse.Status)
  proto3.util.setEnumType(CreateExternalTransactionEnrichmentGroupResponse.Status, "web.public.CreateExternalTransactionEnrichmentGroupResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

