import { FullName as CanadaFullNamePb } from "@repo/protobuf/gen/kikoff_canada/protobuf/types/pii/full_name_pb";
import type { UpdateNameRequest } from "@repo/protobuf/gen/web/public/onboarding_pb";
import { MessageFrom, PartialMessage } from "@repo/protobuf/utils";
import FullName from "@repo/utils/FullName";

export namespace FullNameUsa {
  export const normalize = (
    proto: MessageFrom<UpdateNameRequest>
  ): FullName => ({
    first: proto.givenName,
    middle: proto.middleName,
    last: proto.surname,
  });
  export const denormalize = (
    fullName: FullName
  ): PartialMessage<UpdateNameRequest> => ({
    givenName: fullName.first,
    middleName: fullName.middle,
    surname: fullName.last,
  });
}

export namespace FullNameCanada {
  export const normalize = (
    proto: MessageFrom<CanadaFullNamePb>
  ): FullName => ({
    first: proto.firstName,
    middle: proto.middleName,
    last: proto.lastName,
  });
  export const denormalize = (
    fullName: FullName
  ): MessageFrom<CanadaFullNamePb> => ({
    firstName: fullName.first,
    middleName: fullName.middle,
    lastName: fullName.last,
  });
}
